h1,h3,h2{
    
    color: rgb(2, 82, 52);
    font-weight: bolder;
    margin: 0px;
    margin-bottom:10px
}

.nvul{
  display: none;

}

.hiddenoptions{
  display: none;
}
.visibleoptions{
  display: block;
  
}

.cakescontent{
  position: relative;
  margin: 30px 0px ;
}

.pmbutton{
  border: none;
  background-color: transparent;
  cursor: pointer;
 
  padding: 0px;
  margin: 0px;
  
}


.Checkout{
  position:fixed;
  z-index: 3000;
  top:30vh;
  left:200px
}

p{
    font-weight: bolder;
    padding: 0px;
    margin: 0px
  
}

.opening{
    width: 100%;
    text-align: center;
    font-style: oblique;
    text-decoration: wavy;
}  

.homeTotal{
  width: fit-content;
  padding: 0px;
  margin: 0px;
  color:black
}

.ordercontent{
  padding-left: 10px;
  padding-right: 6px;

}

.introcontent{
  margin-top: 15px;
  padding-left: 10px;
}

.ingredientscontent{
  margin-top: 15px;
  padding-left: 10px;
  padding-bottom: 10px;
}



/*header*/
.headercomp {
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 0px;
    margin: 0px;
    background-color: rgb(251, 222, 202);
    border: 0px;
    background-image: url("./components/img/cakemosaic.png");
    background-position: center;
    background-repeat: repeat;
  }
.headercomp2 {
 
    position: absolute;
    z-index: 1000;
  }


  .ingnovisible{
    display: none;
  }
  .ingvisible{
    display: inline;
    border: none;
    cursor: pointer;
    background-color:transparent;
    text-decoration: underline;
    color: blue;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cartoon {
    width: 150px;
    height: 150px;
}

.divparopening {
    width: 900px;
    margin: 0 auto;  /* This will center the div if its parent is wider than 700px */
}

.paropening {
    display: flex;
    justify-content: center;
    align-items: center;  /* This will center items vertically within the flex container */
}

.paropening p {
    
    margin-right: 15px;
    line-height: 2.0; /* Spacing between the paragraph and the image */
}


  .imgheader{
    background-color: rgb(251, 222, 202);
    margin: 0px;
    padding: 0px;
   
  }
  .imgheader111{
    margin: 0px;
    padding: 0px;
   
  }
  .imgh{
    width: 300px;
    margin-top: 7px;
    padding: 0px;
  }
  .imgh111{
    max-height: 180px;
    margin-top: 7px;
    margin-left: 7px;
  }

/* home */
.ingredientsp{
  font-weight: normal;
  
  margin-bottom: 8px;
}




.nodisplay-more{
  display: none;
}

.navtabs{
  position:fixed;
  z-index: 2500;
  top:1px;
  transform: translate(-50,50);

}




.navlinks{
  
  position: fixed;
  top: 2px;
  right: 2px;
  padding: 0px;
  margin: 0px;
  width: 60%;
  background: transparent;
}

.menuswitch{
  display: none;
}

.links{
  padding: 0px 0px;
  margin: 0px 5px;
  font-weight:bold;
  color: rgb(79, 79, 84);
  font-size: 20px;
  background-color:  rgb(251, 222, 202);;
  border: solid black 1px;
  border-radius: 10px;
}
.read-more-less {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding: 0px;
  margin: 0px;
  border: none;
}

.home{
  position: relative;
    padding: 2px ;
    margin: 0px;
    background-color: rgb(251, 239, 175);
   
}
.homemin{
    padding: 2px ;
    min-height: 85vh;
    margin: 0px;
    background-color: rgb(251, 239, 175);
}


.imageselement {
  position: relative;
  height: 500px; 
  width: 100%; 
  padding: 0px;
  margin: 0px;
  background-color: rgb(251, 239, 200);
}



.newingredients{
  display: flex;
  justify-content: center;
  align-items: center;
}
.newingredientscookies{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ingtitle{
  border: solid rgb(251, 239, 200);
  padding: 10px;
  border-radius: 20px;
  background-color: rgb(251, 239, 200);
}


.imagesCakes {
  max-height: 80%; 
  max-width: 100%;
  object-fit: cover;
}
.orderlink{
  text-decoration: underline;
  color: blue;

}
.imgtext {
  margin: 5px;
  font-family:'Times New Roman', Times, serif;
  font-weight: bolder;
  font-size: 20px;
  word-wrap: break-word;
}
.divtext {
  
  display: flex;
 background-color:rgb(251, 239, 200);;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 border-radius: 5px;
}




.Order{
    /* border: solid black; */
    /* width: fit-content;
    border-radius: 5px;
    padding: 10px 10px; */
    display: flex;
    justify-content:center;
    padding: 0px 5px;
   align-items: center;
   flex-wrap: wrap;
}

.Order2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.cakesNameTotal{
    display: flex;
    margin-right: 5px;
    height: 40px;
    border: solid;
    width: fit-content;
    border-radius: 10px;
    
}

.caketitlediv{    
    margin: 15px;
}

label{
    font-size: 20px;
    margin-right: 5px; 
   
}

select{
    font-size:15px;
}

h3{
    margin-right: 5px;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.qty{
    width: 45px;
    text-align: center;
    height: 22px;
    padding:0px;
    vertical-align: middle;
    display: inline-block;
    margin: 0px;
    align-items: center;
    border: solid 1px black;
    
}

.btplusminus{
    width: 25px;
    height: 24px;
    text-align: center;
    padding:0px;
    
    
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: middle;
    
    border: solid 1px black; 
}






p{
    font-size: 18px;
}

.btdiv{
    height: 24px;
    margin-top: 10px
   ;
    vertical-align: middle;
   margin-bottom: 10px;
}
.btdiv6{
margin-left: 10px;
}
.btdivflex{
display: flex;
align-items: center;
}


.cakeflex{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.placeorderbtn{
   font-size: 17px;
   padding: 5px ;
   margin-top: 5px;
   border-radius: 10px;
   background-color: aquamarine;
   font-weight: bold;

}


.photosOrder{
    width: 100px;
    padding-bottom: 10px;
}

.photoOrder{
    align-items: center;
    display: flex;
}
.placeorderholder{
   font-size: 15px;
   padding: 10px ;
   margin-top: 10px;
   border-radius: 20px;
   background-color: rgb(59, 68, 65);
   font-weight: bold;
   text-decoration: line-through;
}

.cakesIngr{
    font-weight: bolder;
}


.headerorder{
  height: 60px;
  background-color: rgb(243, 189, 189);
  border-radius: 50%;
  padding: 0px 15px;
  margin: 5px 5px;
  display: flex; /* Enable flexbox */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content (optional) */
  text-align: center; /* Center text alignment */
flex-wrap: wrap;
}
.headerorder p{
font-weight: normal;

}
.headerorder h3{
  text-align: center;
text-decoration: underline;

}

@media screen and (max-width: 980px){


.divparopening {
    width: 100%;
    margin: 0px;
    padding-left: 6px;  /* This will center the div if its parent is wider than 700px */
}


.Checkout{
  left: 100px;
}

.paropening {
    display: flex;
    justify-content: center;
    align-items: center;  /* This will center items vertically within the flex container */
}

.paropening p {
    
   
    line-height: 1.5; /* Spacing between the paragraph and the image */
}
}

@media screen and (max-width: 760px) {
  .Checkout{
    left:15px
  }

  .btsorder{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 620px) {
    .headercomp {
        background-size:200px ;
      }

      .paropening p {
    
   
        line-height: 1.0; /* Spacing between the paragraph and the image */
    }

  
  } 
  @media screen and (max-width: 620px) {
      .imgh {
        width:200px ;
      }

  } 
  @media screen and (max-width: 620px) {
      .cartoon {
        width:90px ;
        height:90px ;
      }

  } 
  @media screen and (max-width: 420px) {
 

    .placeorderbtn{
      padding: 0px;
    }
  } 
  @media screen and (max-width: 523px) {
 
    .Checkout{
      left: unset;
      right: 5px;
    }
    .placeorderbtn{
      padding: 0px;
    }
  } 
  @media screen and (max-width: 388px) {

    .headercomp{
      padding: 5px 10px;
    }
      .navtabs {
        display: none;
      }

      .menuswitch{
        display: flex;
        width: 100%;
       
      justify-content: flex-end;
        padding: 10px 0px;
        font-size: 15px;
        
        background-color: transparent;
       
       
    
        color: black;
        
    
        
    
        
      }
    
      .menuswitch h1{
        background-color:rgb(251, 222, 202);;
        margin: 5px 10px;
        padding: 0px;
        font-size: 15px;
        
        text-align: center;
        transform: scale(2.0);
       
      }
    
    
    
    
      .nvul{
        display: none;
     
      }
    
      .navlinks:hover .nvul {
        display: flex;
        font-size: large;
        list-style: none;
        padding: 0px;
        margin: 0px;
       background: transparent;
        justify-content: center;
        
        flex-direction: column;
    
      }

  } 
  @media screen and (max-width: 340px) {
      h1 {
      font-size: 22px;
      }

      h2{
        font-size: 20px;
      }
      h3{
        font-size: 18px;
      }

      .cakesIngr{
        font-size: 16px;
      }

      .imgh{
        width: 190px;
      }

      .headercomp {
        background-size:140px ;
      }

      .photosOrder{
        width: 60px;
      }

      .cartoon{
        width: 60px;
        height: 60px;
      }

  } 
   
  @media screen and (max-width: 291px) {
   .cakeflex{
    justify-content: start;
   }

   .imgh{
    width: 150px;
  }
   
      


  } 
  @media screen and (max-width: 270px) {
      .photosOrder {
      display: none;
      }




  } 
  @media screen and (max-width: 245px) {
.menuswitch{
  padding: 5px 0px;
  transform: scale(0.95);
  margin: 0px 5px;
}
.menuswitch h1{
  font-size: 12px;
}




  } 
  @media screen and (max-width: 236px) {
      .cartoon {
      display: none;
      }

      

      /* .imagesCakes{
        width: 158px;
      } */

     .imgtext{
        font-size: 10px;
     }

       .imgh{
    width: 130px;
  }




  } 

  @media screen and (max-width: 325px) {
    p{
    font-size: 15px;
    }




} 

.ordercontainer{
  padding: 10px;
  min-height: 85vh;
  background-color: rgb(251, 239, 175);
}

.formOrder{
  width: 500px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.summarydiv{
  width:500px
}

.tableOrder{
display: flex;
flex-direction: column;
}

.labelorder{
  display: block;
}

.inputorder{
  display: block;
  height: 25px;
  width: 100%;
}

/* .citySlect{

} */

.citytoselect{
  width:150px
}

.inputZip{
  width:100px
}

.deliveryD{
  width: 152px
}
.deliveryT{
  width: 150px
}

.inputdiv{
  padding: 4px;
}

.proceedBtn{
  display: block;
  width:150px;
  height: 40px;
  font-size: 16px;
  border-radius: 7px;
}
.textProceedBtn{
  display: block;
  width:180px;
  text-align: center;
}

.cancelBtn{
  display: block;
  width:180px;
  height: 40px;
  font-size: 18px;
  border-radius: 7px;
}

.requireText{
  padding-bottom: 2px;
  padding-top: 2px;
}





.grid-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; /* Vertical centering */
 
  min-height: 70vh; /* Adjust this value based on your requirements */
  padding: 10px;
  background-color: rgb(251, 239, 175);
}



.paypalbtns{
  width:500px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
}


.products, .bakery {
  position: relative;
  min-height: 800px;
   padding: 0 auto;
  margin:  0 auto;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 100
}

/* Background images */
.background-image {
  position: absolute;
  top: 0;
  min-height: 600px;
  width: 100%;
  left: 0;
  z-index: 0; /* Push the image behind the content */
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
  padding: 20px;
  font-size: medium;
  border-radius: 10px;
  text-align: center;
  color: white;
}

.landingpageh3 {
  color: white;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.btn:hover {
  background-color: #45a049;
}

.divFP{
  padding: 10px 50px;
  margin:0px 0px;

}

.centecontainerfp{
  margin: 20px;
}

.btncenter{
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 15px;
}

.headerFP{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 00px 0px;
}

.fivimgFP img{
max-width: 300px
}

.divcontactFP{
  display: flex;
  justify-content: center;
  align-items: center;
}
  .divFPmail{
padding: 15px 15px;
  }
  .divFPmail img{
max-height: 28px;
  }
.divFPWs{
  padding: 15px 15px;
} 
.divFPWs img{
  max-height: 30px;
  }



@media screen and (max-width: 541px) {
  .ordercontainer{
  font-size: 15px
  }

  .formOrder{
  width: 100%;
}

.summarydiv{
  width:100%
}

.tableOrder{
display: flex;
flex-direction: column;
}

.labelorder{
  display: block;
}

.inputorder{
  
  width: 100%;
}


.insOrder{
  width: 100%;
}

.paypalbtns{
  width: 100%;
  
  
}

}

@media screen and (max-width: 200px) {
  .proceedBtn{
    width:100%;
;
  }
  .textProceedBtn{
    
    width:100%;
    
  }
  
  .cancelBtn{
    
    width:100%;
  
  }

}
@media screen and (max-width: 220px) {
 .Order{
  padding: 0px;
  margin: 0px;
 }

 .btdiv{
  padding: 1px;
  margin: 0px;
 }

 .btdivflex{
  padding: 0px;
  margin: 0px;
 }

 .cakeflex{
  justify-content: flex-start;
  padding: 0px;
  margin: 0px;
 }

}

.footer{
  display: flex;
  justify-content: center;
  background-color: 
  rgb(251, 222, 202);
  margin: 0px;
  padding: 0px;
}

.followus{
  border-right: solid black;
 
}
.followus{
  padding:3px;
  padding-right: 20px;
 
}
.followus a{
  
  padding:5px;
}
.followus h4{
  
  padding:0px;
  margin: 0px;
  margin-bottom: 10px;
}
.contactus{
  
  padding:3px;
  padding-left: 5px;
  margin-left: 20px;
  
}
.contactus h4{
  padding:0px;
  margin: 0px;
  margin-bottom: 10px;
}
.contactus h5{
  
  padding:0px;
  margin: 0px;
  margin-bottom:10px;
}

.wsapctcus{
  display: flex;
  margin-bottom:10px;
  align-items: center;
}


.fillerfooter{
  display: flex;
  justify-content: center;
  background-color: 
  rgb(251, 222, 202);
  color:rgb(251, 222, 202) ;
  margin: 0px;
  padding: 0px;
}
.fillerfooter a{
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 150px;
  font-size: 12px;
}


@media screen and (max-width: 350px) {


  .followus{
    padding:0px;
    padding-right: 10px;
   
  }

  .contactus{
    
    padding:0px;
    padding-left: 2px;
    margin-left: 10px;
    
  }
  .contactus h4{
    padding:0px;
    margin: 0px;
    margin-bottom: 10px;
  }
  .contactus h5{
    word-break: break-all;
    padding:0px;
    margin: 0px;
    margin-bottom:10px;
  }
  



}

.separator{
  display:block;
  width: 100%;
}

.containercarrousel{
  display:block;
  width: 100%;
  padding: 20px 0px;
}
.containercarrousel2{
  display:block;
  width: 100%;
  padding: 20px 0px;
}

.imagescarrouselcontainter{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding:0px 0px;
  height: 550px;
  overflow: hidden;
  background-color: rgb(251, 239, 200);
}


.imagescarrousel{
max-width: 100%;
 max-height: 90%;
  margin: 0px;
  padding: 0px ;
  object-fit: contain;

}

.imagescarrouseltamal{
max-width: 100%;
 max-height: 80%;
  margin: 0px;
  padding: 0px 

}

.textimagescarrousel{
  padding: 10px 0px;
 
  font-weight: bold;
  font-size: 18px;
  word-break: break-all;
}

.imagescarrouselcontainter2{
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: rgb(251, 239, 200);
}
.imagescarrousel2{
max-width: 100%;
 max-height: 600px;
  margin: 0px;
  padding: 0px ;


}

.carousel-slide {
 display: block;
 text-align: center;
 background-color: rgb(251, 239, 200);

}
.imgcarrousel2 {
  margin: auto;
max-height: 600px;
background-color: rgb(251, 239, 200);

}

.mycarrousel .slick-dots {
  background-color: rgb(251, 239, 200); /* Replace with your desired background color */
 /* Optionally, add border-radius for rounded corners */
}
.mycarrousel .slick-track {
  background-color: rgb(251, 239, 200); /* Replace with your desired background color */
 
 /* Optionally, add border-radius for rounded corners */

}

button.slick-arrow.slick-next{
margin-right: 30px;
opacity: 0%;
}

.separator2{
  background-color: rgb(251, 239, 200);
 padding-top: 20px;
  display:block;
  width: 100%;
}
.separator2a{
  
  display:block;
  width: 100%;
}

.watsaplogolink{
  
  display: none;



}



@media screen and (max-width: 510px) {
  .imagescarrouselcontainter{
    height: 450px;
  }

  .watsaplogolink{
    position:fixed;
    display: block;
    right: 8px;
    bottom: 110px;
    cursor: pointer;
    z-index: 20000;
    font-weight: bolder;
  
  
  }
    
}
@media screen and (max-width: 442px) {
  .imagescarrouselcontainter{
    height: 400px;
  }

}
@media screen and (max-width: 600px) {
  .imgcarrousel {
    margin:auto;
  max-height: 600px;
  background-color: rgb(251, 239, 200);
  
  }
  
}
@media screen and (max-width: 600px) {
  .imgcarrousel2 {
    max-width: 100%;
    height: auto;
    display: block; /* Remove extra space below the image */
    margin: 0 auto; /* Center the image horizontally */
  
  background-color: rgb(251, 239, 200);
  
  }
  
}

/* .products, .bakery {
  position: relative;
  min-height: 800px;
   padding: 0 auto;
  margin:  0 auto;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 100
}

.background-image {
  position: absolute;
  top: 0;
  min-height: 600px;
  width: 100%;
  left: 0;
  z-index: 0; 
} */

@media screen and (max-width: 850px) {
  .overlay-text {
width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .background-image {
    width: 100%;
    min-height: unset;
    height: auto;

  }
  .products, .bakery {
    min-height: 500px;
  }
}

@media screen and (max-width: 440px) {
  .overlay-text {

    top: 250px;

  }
.imgh111{
  
    min-height: unset;
 height: 120px;

}
}
@media screen and (max-width: 308px) {
  .overlay-text {

    top: 240px;

  }
.imgh111{
  
    min-height: unset;
 height: 120px;

}
}
@media screen and (max-width: 308px) {
  .bakery .overlay-text {

    top: 180px;

  }
.imgh111{
  
    min-height: unset;
 height: 120px;

}
}



@media screen and (max-width: 550px) {

.fivimgFP img{
max-width: 200px
}
  .divFPmail{
margin: 0px 0px;
padding: 0px 0px;
  }
  .divFPmail img{
max-height: 20px;
  }
.divFPWs{
  margin: 0px 15px;
  padding: 0px 0px;
} 
.divFPWs img{
  max-height: 25px;
  }

}
@media screen and (max-width: 350px) {

.fivimgFP img{
max-width: 170px
}
  .divFPmail{
margin: 0px 0px;
  }
  .divFPmail img{
max-height: 18px;
  }
.divFPWs{
  margin: 0px 10px;
} 
.divFPWs img{
  max-height: 20px;
  }

}
@media screen and (max-width: 250px) {

.fivimgFP img{
max-width: 110px
}
  .divFPmail{
margin: 0px 5px;
  }
  .divFPmail img{
max-height: 18px;
  }
.divFPWs{
  margin: 0px 0px;
} 
.divFPWs img{
  max-height: 20px;
  }

}







